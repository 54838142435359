import * as actions from '../../actionTypes';
import { combineReducers } from 'redux';

const completeSteps = (state = [], action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      //TODO abstrair lista de step
      return action.payload.conferido ? [0, 1, 2, 3, 4, 5] : state;
    case actions.SET_STEP:
      let step = action.payload.step - 1;
      if (state.indexOf(step) <= -1) {
        state.push(step);
      }

      return state;
    default:
      return state;
  }
};

const currentStep = (state = -1, action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      return action.payload.conferido ? 5 : state;
    case actions.SET_STEP:
      return action.payload.step;
    default:
      return state;
  }
};

export default combineReducers({
  completeSteps,
  currentStep,
});
