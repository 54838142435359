import * as actions from '../../actionTypes'
import * as constants from '../../constants'
import LocalStorage from '../../utils/LocalStorage'

const errors = {
    USUARIO_INVALIDO: 'Usuário ou senha inválido',
    CREDENCIAIS_INVALIDAS: 'Usuário ou senha inválido',
    ERRO_DESCONHECIDO: 'Erro! Tente novamente mais tarde'
}

export const isAuthenticated = () => {
    if (!LocalStorage.get(constants.USERGS)) {
        return false
    }
    // TODO validar expire_in
    // if (utils.isPast(LocalStorage.get(constants.USERGS).expire_in)) {
    //     LocalStorage.remove(constants.USERGS)
    //     return false
    // }

    return true
}

const login = (state = {
    isFetching: false,
    isRecoveryCredentials: false,
    isRecoveryError: null,
    errorLogin: null,
    errorRecovery: null,
    isAuthenticated: isAuthenticated(),
}, action) => {
    switch (action.type) {
        case actions.TOGGLE_RECOVERY_CREDENTIALS:
            return {
                ...state,
                isRecoveryCredentials: !state.isRecoveryCredentials,
                isRecoverySuccess: false,
                isFetching: false
            }
        case actions.FETCH_LOGIN_ERROR:
            return {
                ...state,
                isFormError: true,
                isFetching: false,
                errorLogin: action.payload && action.payload.errorLogin
                    ? errors[action.payload.errorLogin] || errors.ERRO_DESCONHECIDO
                    : errors.ERRO_DESCONHECIDO
            }
        case actions.FETCH_LOGIN_PENDING:
            return {
                ...state,
                isFetching: true,
                isFormError: false,
                errorLogin: null,
            }
        case actions.FETCH_LOGIN_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isAuthenticated: true,
            }
        case actions.LOGOUT:
            return {
                ...state,
                isAuthenticated: false
            }
        case actions.RECOVERY_PENDING:
            return {
                ...state,
                isRecoverySuccess: false,
                isFetching: true
            }
        case actions.RECOVERY_ERROR:
            return {
                ...state,
                isFetching: false,
                isRecoveryError: errors[action.payload.error] || errors.ERRO_DESCONHECIDO
            }
        case actions.RECOVERY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                isRecoverySuccess: true
            }
        default:
            return state
    }
}

export default login