import React, { Component, Fragment } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _groupBy from 'lodash/groupBy';

import { uploadDocumento, atualizaDocumento } from './DocumentoUploadActions';
import { validateDocuments } from '../../Cliente/ClienteActions';
import CardDocumento from './CardDocumento';
import PanelFooter from '../PanelFooter';

class CamposDocumentos extends Component {
  state = {
    documentoModal: null,
  };
  static propTypes = {
    documentos: PropTypes.array.isRequired,
    atualizaDocumento: PropTypes.func.isRequired,
    uploadDocumento: PropTypes.func.isRequired,
  };

  setDocumentoModal = documento => {
    this.setState({
      documentoModal: documento,
    });
  };

  onRemove = documento => {
    documento = {
      ...documento,
      old_url: documento.url,
      url: null,
    };

    this.props.atualizaDocumento(documento);
  };

  onSelectImage = (file, documento) => {
    this.props.uploadDocumento(file, documento);
  };

  onThumbCreated = (Thumb, documento) =>
    this.props.atualizaDocumento({ ...documento, Thumb });

  render() {
    const { documentoModal } = this.state;
    const {
      documentos,
      haveErrors,
      validateDocuments,
      onContinue,
    } = this.props;

    const onValidate = () => {
      validateDocuments();
      onContinue();
    };

    let documentosGrupo = _groupBy(documentos, 'ordem');

    const todosDocumentosInseridos = documentos.every(
      documento => documento.url
    );

    const temErrosAtualizados = {
      ...haveErrors,
      documentos: !todosDocumentosInseridos,
    };

    return (
      <div className="CamposDocumentos">
        <small className="d-block text-secondary">
          *arquivos permitidos: PDF de até 500Kb
        </small>
        <Row>
          {Object.keys(documentosGrupo)
            .sort()
            .map((item, index) => {
              const itemDocumento = documentosGrupo[item];

              return (
                <Col xs="12" sm="6" md="4" key={index}>
                  <h4>{itemDocumento[0].tipo}</h4>
                  <hr />
                  {itemDocumento.length > 0 && (
                    <Fragment>
                      <ul className="list-unstyled">
                        {itemDocumento.map((documento, index) => {
                          return (
                            <CardDocumento
                              key={index}
                              documento={documento}
                              onRemove={this.onRemove}
                              onClickDocumento={this.setDocumentoModal}
                              onSelectImage={this.onSelectImage}
                              onThumbCreated={this.onThumbCreated}
                            />
                          );
                        })}
                      </ul>
                    </Fragment>
                  )}
                </Col>
              );
            })}
        </Row>

        {documentoModal && (
          <Modal
            show={documentoModal !== null}
            onHide={() => this.setDocumentoModal(null)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <small>{documentoModal.descricao}</small>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <a
                href={documentoModal.url}
                title="Clique na imagem para visualizar o PDF"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={documentoModal.Thumb}
                  alt={documentoModal.descricao}
                  className="img-fluid"
                />
              </a>
            </Modal.Body>
          </Modal>
        )}

        <div className="alert alert-info border-0 mb-5">
          <small>
            Fique tranquilo! Os documentos são salvos automaticamente, se você
            não tiver todos os documentos em mãos, você pode continuar mais
            tarde.
          </small>
        </div>

        <PanelFooter className="mt-1">
          {temErrosAtualizados.documentos ? (
            <div className="alert alert-danger mb-0 py-1 px-2">
              Verifique os documentos antes de continuar
            </div>
          ) : (
            <div className="m-auto" style={{ maxWidth: 400 }}>
              <button
                onClick={onValidate}
                className="btn btn-block btn-success mb-0"
              >
                Continuar
              </button>
              <small className="text-secondary">
                <i className="fa fa-warning" />
                &nbsp;Clique em <strong>Continuar</strong> se o(s) seu(s)
                documento(s) estiverem corretos
              </small>
            </div>
          )}
        </PanelFooter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  documentos: state.cliente.documentos,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadDocumento,
      atualizaDocumento,
      validateDocuments,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CamposDocumentos);
