import { Component } from 'react';
import { buscarCidade } from '../../service/Service';
import { capitalize } from '../../utils/Utils';

let timeout = null;
export default class BuscarCidade extends Component {
  carregarCidades = (inputValue, callback) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      buscarCidade(this.props.estado, inputValue).then(response => {
        callback(
          response.map(item => ({
            value: item.codigo,
            label: capitalize(item.descricao),
          }))
        );
      });
    }, 500);
  };

  render() {
    return this.props.children({
      carregarCidades: this.carregarCidades,
    });
  }
}
