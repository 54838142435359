import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendCliente } from '../Cliente/ClienteActions';

const mapStateToProps = state => ({
  panel: state.panel,
  cliente: state.cliente,
  opcoes: state.opcoes,
  haveErrors: state.haveErrors,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendCliente,
    },
    dispatch
  );

let PanelEnviar = ({ cliente, haveErrors, sendCliente }) => {
  const messageError =
    typeof cliente.isSendingError === 'string' &&
    cliente.isSendingError === 'EMPREENDIMENTO_SEM_CAMPANHA'
      ? 'A campanha já encerrou'
      : 'Erro ao salvar! Por favor, tente novamente mais tarde';
  return (
    <div className="Panel PanelEnviar">
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="alert alert-secondary">
            <strong>Atenção! </strong>
            <br />
            Após enviar os dados, as alterações só serão possíveis através do
            seu corretor!
          </div>
          {cliente.isSendingError && (
            <div className="alert alert-warning mt-3">{messageError}</div>
          )}

          <button
            className="mt-3 mr-3 px-5 py-2 btn btn-secondary btn-lg shadow-lg rounded-0"
            onClick={() => window.location.reload()}
          >
            <small>
              <i className="fa fa-chevron-left" />
            </small>
            &nbsp;&nbsp;VOLTAR AO INÍCIO
          </button>

          <button
            className="mt-3 px-5 py-2 btn btn-success btn-lg shadow-lg rounded-0"
            onClick={sendCliente}
            disabled={cliente.isLoading || cliente.isSending || haveErrors}
          >
            {cliente.isSending ? (
              <span>
                <i className="fa fa-circle-o-notch" /> Enviando...
              </span>
            ) : (
              <span>
                ENVIAR OS DADOS&nbsp;&nbsp;
                <small>
                  <i className="fa fa-chevron-right" />
                </small>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelEnviar);
