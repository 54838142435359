import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default class PanelToggler extends Component {
  constructor(props) {
    super(props);
    this.panelRef = React.createRef();
  }

  static propTypes = {
    isActive: PropTypes.bool,
    title: PropTypes.string,
    haveError: PropTypes.any,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  scrollToDomRef = () => {
    const myDomNode = ReactDOM.findDOMNode(this.panelRef.current);
    window.scrollTo(0, myDomNode.offsetTop);
  };

  componentDidUpdate(prevProps) {
    if (prevProps && !prevProps.isActive && this.props.isActive) {
      this.scrollToDomRef();
    }
  }

  render() {
    const {
      children,
      isActive,
      title,
      haveError,
      isLoading,
      onOpen,
      onClose,
    } = this.props;

    const errorIcon = haveError ? (
      <i
        title="Ainda existem campos não preenchidos"
        className={`fa fa-exclamation-triangle text-${
          isActive ? 'danger' : 'warning'
        }`}
      />
    ) : (
      <i
        title="Tudo certo por aqui"
        className={`fa fa-check-square-o text-${
          isActive ? 'white' : 'success'
        }`}
      />
    );

    return (
      <div ref={this.panelRef} className={'PanelToggler card mb-2'}>
        {isActive ? (
          <Fragment>
            <div className="card-header clearfix bg-dark text-white">
              <button
                className="btn btn-link float-left text-white"
                onClick={onClose}
              >
                <i className="fa fa-chevron-left" /> Voltar
              </button>
              <p className="mb-0 pt-2 float-right text-right">
                {title} {errorIcon}
              </p>
            </div>
            <div className="card-body">{children}</div>
          </Fragment>
        ) : (
          <div
            className="card-body is-closed"
            style={{ cursor: !isLoading && 'pointer' }}
            onClick={() => {
              if (!isLoading) {
                onOpen();
              }
            }}
          >
            <Fragment>
              <p className="text-primary mb-0 PanelToggler__title">
                {!isLoading && (
                  <span>
                    {title} {errorIcon}
                  </span>
                )}
              </p>
              <small className="text-muted PanelToggler__subtitle">
                {!isLoading && (
                  <span>
                    Clique aqui para conferir os dados de <b>{title}</b>
                  </span>
                )}
              </small>
            </Fragment>
          </div>
        )}
      </div>
    );
  }
}
