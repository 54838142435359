import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FiArrowRight } from 'react-icons/fi';

import { goToNextStep } from '../Scene/SceneAction';
// import imgCoupon from '../../images/coupon.svg';
import imgForm from '../../images/form.svg';
import imgRegister from '../../images/register.svg';
import { NOME_EMPREENDIMENTO } from '~/constants';

const mapStateToProps = state => ({
  panel: state.panel,
  cliente: state.cliente,
  opcoes: state.opcoes,
  haveErrors: state.haveErrors,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      goToNextStep,
    },
    dispatch
  );

let PanelStart = ({ goToNextStep }) => {
  const start = [
    {
      img: imgRegister,
      complete: true,
      label: 'Confira e corrija, se necessário, os seus dados.',
    },
    {
      img: imgForm,
      label: 'Envie os seus documentos.',
    },
    // {
    //   img: 'https://cdn-icons-png.flaticon.com/512/1358/1358533.png',
    //   label: 'Receba as instruções para assinar seu contrato!',
    // },
  ];
  return (
    <div className="PanelStart text-center">
      <h2 className="PanelStart__title">
        Faça a validação do seu cadastro e garanta que seu dados estejam
        corretos para o lançamento do <strong>{NOME_EMPREENDIMENTO}</strong>.
      </h2>
      <ul className="PanelStart__list list-unstyled">
        {start.map((item, index) => (
          <li
            className={`PanelStart__list__item ${item.complete &&
              'is-complete'}`}
            key={index}
          >
            <div className="PanelStart__list__item__icon">
              <img src={item.img} alt={item.label} />
            </div>
            <div className="PanelStart__list__item__label">{item.label}</div>
          </li>
        ))}
      </ul>
      <button
        className="btn btn-primary PanelStart__btn-avancar"
        onClick={goToNextStep}
      >
        Iniciar validação de cadastro <FiArrowRight size={20} />
      </button>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelStart);
