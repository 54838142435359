import * as actions from '../../actionTypes';
import { combineReducers } from 'redux';

const active = (state = null, action) => {
  switch (action.type) {
    case actions.SHOW_PANEL:
      return action.payload;
    default:
      return state;
  }
};

const panels = (
  state = {
    DADOSPESSOAIS: 'DADOSPESSOAIS',
    SOCIOS: 'SOCIOS',
    PESQUISA: 'PESQUISA',
    DOCUMENTOS: 'DOCUMENTOS',
  },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default combineReducers({
  panels,
  active,
});
