import { isRequired } from './components/Form/Validator';
import * as utils from './utils/Utils';

export const DEFAULT_ENV = 'prod';
// process.env.NODE_ENV !== 'production' ? 'hml' : 'prod';

export const ENV = [
  { value: 'prod', label: 'Produção' },
  { value: 'hml', label: 'Treinamento' },
  { value: 'dev', label: 'Desenvolvimento' },
];

export const CODIGO_CORRETOR_SETPAR = 1078;
export const NOME_COMPLETO_EMPREENDIMENTO =
  'SetValley Horizonte - São José dos Campos/SP';
export const NOME_EMPREENDIMENTO = 'SetValley Horizonte';
export const LOGO_EMPREENDIMENTO = './logo_empreendimento.png';
export const TEXTO_SUCESSO =
  'Você está próximo de garantir seu Kit Churrasco (KIT BBQ). Mantenha contato com seu corretor para se informar de mais novidades e a data do lançamento para comprar o seu terreno.';
export const CTA_SUCESSO = 'Clique aqui para baixar o seu comprovante';
export const LINK_EMPREENDIMENTO = 'https://setlife.setpar.com.br/3d-setvalley';
export const CTA_SITE_EMPREENDIMENTO = 'Ir para o tour virtual!';
export const LINK_SEMCADASTRO =
  'https://www.setpar.com.br/setvalley-horizonte/';
export const CODIGO_EMPREENDIMENTO = 11452;

export const TAMANHO_CEP = 8;

export const MAX_SIZE_DOCUMENTO = 500000; // bytes
export const TIPO_UPLOAD_PDF = 'application/pdf';
export const TIPOS_UPLOAD_DOCUMENTO_PROSPECTO = [TIPO_UPLOAD_PDF, 'image/*'];

export const BASENAME = '';
export const ESTADOS = {
  AC: 'Acre',
  AL: 'Alagoas',
  AM: 'Amazonas',
  AP: 'Amapá',
  BA: 'Bahia',
  CE: 'Ceará',
  DF: 'Distrito Federal',
  ES: 'Espírito Santo',
  GO: 'Goiás',
  MA: 'Maranhão',
  MG: 'Minas Gerais',
  MS: 'Mato Grosso do Sul',
  MT: 'Mato Grosso',
  PA: 'Pará',
  PB: 'Paraíba',
  PE: 'Pernambuco',
  PI: 'Piauí',
  PR: 'Paraná',
  RJ: 'Rio de Janeiro',
  RN: 'Rio Grande do Norte',
  RO: 'Rondônia',
  RR: 'Roraima',
  RS: 'Rio Grande do Sul',
  SC: 'Santa Catarina',
  SE: 'Sergipe',
  SP: 'São Paulo',
  TO: 'Tocantins',
};

export const TIPOS_PESQUISA = {
  DISSERTATIVA: 'DISSERTATIVA',
  ESCOLHA: 'ESCOLHA',
  PESO: 'PESO',
  ESTRELA: [10],
  MULTIPLA_ESCOLHA: 'MULTIPLA_ESCOLHA',
};

export const QUESTION_MAX_LENGTH = { id: 11, length: 5000 };

export const MASK_DATE = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const MASK_CPF = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const MASK_CNPJ = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const MASK_CEP = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
export const MASK_CEL = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const MASK_CEL_9 = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const DATE_FORMAT_SERVER = 'YYYY-MM-DDTHH:mm:ss';

export const RECOVERY_PASSWORD_ENABLE = false;

export const USERGS = 'USERGS';

export const PERGUNTAS_PARENTES = {
  /*
    Codigo da pergunta que deve ser exibida: {
        parent: {
            Pergunta: Codigo da pergunta já exibida,
            resposta: Resposta que ativa a pesquisa a exibir
        }
    }
    */
  88: { parent: { pergunta: 87, resposta: [436, 437, 438] } },
  108: { parent: { pergunta: 107, resposta: [487, 490, 491] } },
  109: { parent: { pergunta: 107, resposta: [488, 489] } },
  113: { parent: { pergunta: 112, resposta: [516] } },
};

/**
 * CUSTOMIZAR_PESQUISA
 *
 * {
 *  mask: (array de regex) renderiza um MaskedInput aplicando a máscara
 *  noGuide: (boolean - requer mask) deixa de exibir as guias do MaskedInput
 *  placeholder: (string) substitui o placeholder padrão
 *  selector: (boolean) renderiza um Selector (select com campo de busca)
 *  multiple: (boolean - requer selector) permite selecionar múltiplos valores no Selector
 * }
 */
export const CUSTOMIZAR_PESQUISA = {};

const isRequiredOneOf = (values, fields) =>
  fields.reduce((prev, curr) => {
    return prev || values[curr];
  }, false);

const phoneValidations = (value, values) =>
  !isRequiredOneOf(values, [
    'tel_comercial',
    'tel_celular',
    'tel_residencial',
  ]) && 'Preencha ao menos um dos telefones';

const isValidPhone = value =>
  value && !utils.isValidPhone(value) && 'Insira um telefone válido';

const isValidCelPhone = value =>
  value && !utils.isValidPhone(value, 11) && 'Insira um telefone válido';

const isValidDate = value =>
  value && !utils.isValidDate(value) && 'Precisa ser uma data válida';

const emissorCNHValidation = (value, values) =>
  values.CNH && !value && 'Campo obrigatório';

const contactValidation = (value, values) =>
  !values.email &&
  !values.tel_celular &&
  'Informe pelo menos uma forma de contato';

const isValidCep = value =>
  (!value || (value && value.replace(/\D/g, '').length < 8)) &&
  'Campo obrigatório';

const isValidEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    email && !re.test(String(email).toLowerCase()) && 'Insira um email válido'
  );
};

export const ORGAO_EMISSOR_CNH = [
  { value: '', label: 'Selecione Orgão Emissor' },
  { value: 'DETRAN/AC', label: 'DETRAN/AC' },
  { value: 'DETRAN/AL', label: 'DETRAN/AL' },
  { value: 'DETRAN/AM', label: 'DETRAN/AM' },
  { value: 'DETRAN/AP', label: 'DETRAN/AP' },
  { value: 'DETRAN/BA', label: 'DETRAN/BA' },
  { value: 'DETRAN/CE', label: 'DETRAN/CE' },
  { value: 'DETRAN/DF', label: 'DETRAN/DF' },
  { value: 'DETRAN/ES', label: 'DETRAN/ES' },
  { value: 'DETRAN/GO', label: 'DETRAN/GO' },
  { value: 'DETRAN/MA', label: 'DETRAN/MA' },
  { value: 'DETRAN/MG', label: 'DETRAN/MG' },
  { value: 'DETRAN/MS', label: 'DETRAN/MS' },
  { value: 'DETRAN/MT', label: 'DETRAN/MT' },
  { value: 'DETRAN/PA', label: 'DETRAN/PA' },
  { value: 'DETRAN/PB', label: 'DETRAN/PB' },
  { value: 'DETRAN/PE', label: 'DETRAN/PE' },
  { value: 'DETRAN/PI', label: 'DETRAN/PI' },
  { value: 'DETRAN/PR', label: 'DETRAN/PR' },
  { value: 'DETRAN/RJ', label: 'DETRAN/RJ' },
  { value: 'DETRAN/RN', label: 'DETRAN/RN' },
  { value: 'DETRAN/RO', label: 'DETRAN/RO' },
  { value: 'DETRAN/RR', label: 'DETRAN/RR' },
  { value: 'DETRAN/RS', label: 'DETRAN/RS' },
  { value: 'DETRAN/SC', label: 'DETRAN/SC' },
  { value: 'DETRAN/SE', label: 'DETRAN/SE' },
  { value: 'DETRAN/SP', label: 'DETRAN/SP' },
  { value: 'DETRAN/TO', label: 'DETRAN/TO' },
];

export const ESTADO_CIVIL_CLIENTE = [
  { value: 'T', label: 'Solteiro' },
  { value: 'C', label: 'Casado em regime de comunhão de bens' },
  { value: 'P', label: 'Casado em regime de comunhão parcial de bens' },
  { value: 'B', label: 'Casado em regime de comunhão universal de bens' },
  { value: 'E', label: 'Casado em regime de participação final nos aquestos' },
  { value: 'S', label: 'Casado em regime de separação de bens' },
  { value: 'U', label: 'União Estável' },
  { value: 'D', label: 'Divorciado' },
  { value: 'J', label: 'Separado Judicialmente' },
  { value: 'V', label: 'Viúvo' },
];

export const ESTADO_CIVIL_CS = [
  { value: 'C', label: 'Cônjuge / Rep. Legal' },
  { value: 'S', label: 'Sócio' },
  { value: 'J', label: 'Cônjuge do sócio' },
  { value: 'O', label: 'Outros' },
  { value: 'P', label: 'Procurador' },
  { value: 'N', label: 'Cônjuge do procurador' },
];

//Utilizado para exibir os * nos campos
export const REQUIREDS_FIELDS = {
  PRINCIPAL:
    'nome, sexo, tel_celular, nascimento, nacionalidade, cep, estado, endereco, numero, bairro, codigo_cidade, profissao, rg, emissor_rg, data_emissao_rg',
  SOCIO:
    'nome, sexo, tel_celular, nascimento, nacionalidade, cep, estado, endereco, numero, bairro, codigo_cidade, profissao, rg, emissor_rg, data_emissao_rg',
  CONJUGE:
    'nome, sexo, tel_celular, nascimento, nacionalidade, profissao, rg, emissor_rg, data_emissao_rg',
  PRINCIPAL_PJ:
    'nome, tel_celular, cep, estado, endereco, numero, bairro, codigo_cidade',
};

const VALIDATIONS_PRINCIPAL = {
  nome: [isRequired],
  sexo: [isRequired],
  email: [contactValidation, isValidEmail],
  tel_comercial: [phoneValidations, isValidPhone],
  tel_celular: [contactValidation, phoneValidations, isValidCelPhone],
  tel_residencial: [phoneValidations, isValidPhone],
  nascimento: [isRequired, isValidDate],
  nacionalidade: [isRequired],
  cep: [isValidCep],
  endereco: [isRequired],
  numero: [isRequired],
  bairro: [isRequired],
  codigo_cidade: [isRequired],
  profissao: [isRequired],
  emissor_cnh: [emissorCNHValidation],
  rg: [isRequired],
  emissor_rg: [isRequired],
  data_emissao_rg: [isRequired, isValidDate],
};

const VALIDATIONS_PRINCIPAL_PJ = {
  nome: [isRequired],
  email: [contactValidation, isValidEmail],
  tel_comercial: [phoneValidations, isValidPhone],
  tel_celular: [contactValidation, phoneValidations, isValidCelPhone],
  tel_residencial: [phoneValidations, isValidPhone],
  cep: [isValidCep],
  endereco: [isRequired],
  numero: [isRequired],
  bairro: [isRequired],
  codigo_cidade: [isRequired],
};

const VALIDATIONS_SOCIO = {
  ...VALIDATIONS_PRINCIPAL,
  email: [isValidEmail],
  tel_comercial: [isValidPhone],
  tel_celular: [isValidCelPhone],
  tel_residencial: [isValidPhone],
};

//Retorna um objeto sem as propriedades declaradas inicialmente
const {
  email,
  tel_comercial,
  tel_celular,
  tel_residencial,
  cep,
  bairro,
  endereco,
  numero,
  codigo_cidade,
  ...VALIDATIONS_CONJUGE
} = VALIDATIONS_PRINCIPAL;

export const VALIDATIONS = {
  PRINCIPAL: VALIDATIONS_PRINCIPAL,
  CONJUGE: VALIDATIONS_CONJUGE,
  SOCIO: VALIDATIONS_SOCIO,
  PRINCIPAL_PJ: VALIDATIONS_PRINCIPAL_PJ,
};

export const CHEQUEBONUS_NOME_ARQUIVO = 'comprovante-setpar-setsul2';
