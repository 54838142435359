import moment from 'moment';
import * as constants from '../constants';
require('moment/locale/pt-br');
moment.locale('pt-br');
window.moment = moment;

export const capitalize = str => {
  if (!str) {
    return str;
  }

  return (
    str &&
    str
      .split(' ')
      .map(item => {
        item = item.toLowerCase().split('');
        if (item[0]) {
          item[0] = item[0].toUpperCase();
        }
        return item.join('');
      })
      .join(' ')
  );
};

const DATE_DEFAULT_FORMAT = 'DD/MM/YYYY';

export const now = () => moment().format(DATE_DEFAULT_FORMAT);

export const getDate = date => moment(date, DATE_DEFAULT_FORMAT);

export const formatDate = (str, format = DATE_DEFAULT_FORMAT) => {
  return moment(str, DATE_DEFAULT_FORMAT).format(format);
};

export const isValidDate = date => {
  //8 = size string digit date 21 01 2018
  if (!date || (date.match(/\d/g) && date.match(/\d/g).length) < 8) {
    return false;
  }

  let expire = moment(date, DATE_DEFAULT_FORMAT);
  return expire.isValid();
};

export const isValidPhone = (phone, validLength = 10) => {
  let numbers = clearNumber(phone);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.length;
  }

  return numberLength >= validLength;
};

export const incrementTime = (seconds, start = moment()) => {
  return start.add(seconds, 's');
};

export const isPast = date => {
  return moment(date).isBefore(moment());
};

const clearString = str => {
  if (!str) {
    return '';
  }

  return str.replace(/\s|-|_|\.|\(|\)/g, '');
};

export const clearNumber = number => {
  number += '';
  number = number.match(/\d/g) || [];
  return number.join('');
};

export const formatPhoneNumber = phone => {
  if (!phone) {
    return constants.MASK_CEL;
  }

  let numbers = clearString(phone);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.length;
  }

  return numberLength > 10 ? constants.MASK_CEL_9 : constants.MASK_CEL;
};

export const formatCPF = cpf => {
  if (!cpf) {
    return constants.MASK_CPF;
  }

  let numbers = clearString(cpf);
  let numberLength = 0;
  if (numbers) {
    numberLength = numbers.length;
  }

  return numberLength <= 11 ? constants.MASK_CPF : constants.MASK_CNPJ;
};

export const slugify = str => {
  return str
    .toString()
    .toLowerCase()
    .trim()
    .normalize('NFD') // separate accent from letter
    .replace(/[\u0300-\u036f]/g, '') // remove all separated accents
    .replace(/\s+/g, '-') // replace spaces with -
    .replace(/&/g, '-and-') // replace & with 'and'
    .replace(/[^\w-]+/g, '') // remove all non-word chars
    .replace(/--+/g, '-'); // replace multiple '-' with single '-'
};
