import LocalStorage from '../utils/LocalStorage';
import * as constants from '../constants';
import axios from 'axios';
import qs from 'querystring';
import { doLogout } from '../components/Login/LoginActions';

const defaultLoginRequest = {
  grant_type: 'password',
  family: constants.CODIGO_EMPREENDIMENTO,
  env: constants.DEFAULT_ENV,
  type: 2,
};

const APIBASE = 'https://api.setcorp.com.br';
//const APIBASE = 'http://localhost:54608';

export const httpService = {
  setupInterceptors: store => {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error.response &&
          (error.response.status === 403 || error.response.status === 401)
        ) {
          store.dispatch(doLogout());
        }

        return Promise.reject(error);
      }
    );
  },
  fetch: async ({ url, data, headers, ...rest }, usarApiBase = true) => {
    let user = LocalStorage.get(constants.USERGS);
    let accessToken = user ? `Bearer ${user.access_token}` : null;

    headers = {
      ...defaultLoginRequest,
      ...headers,
      Authorization: accessToken,
      env: defaultLoginRequest.env,
      user: user ? user.user : null,
    };

    url = usarApiBase ? APIBASE + url : url;

    try {
      const res = await axios({
        url,
        data,
        headers,
        ...rest,
      });
      return res.data;
    } catch (error) {
      console.warn(error);
      throw error.response.data;
    }
  },
  post: (url, data, headers) =>
    httpService.fetch({ url, data, headers, method: 'post' }),
  get: (url, usarApiBase) => httpService.fetch({ url }, usarApiBase),
  put: (url, data, headers) =>
    httpService.fetch({ url, data, headers, method: 'put' }),
};

//Endpoints da API
export const doLogin = credentials =>
  httpService.post(
    '/usuario/login',
    qs.stringify({ ...defaultLoginRequest, ...credentials }), //data
    { 'Content-Type': 'application/x-www-form-urlencoded' } //headers
  );

export const doRecoveryCredentials = user =>
  httpService.post('/usuario/login/recuperar', user);

export const getOpcoes = () => httpService.get('/opcao');

export const getQuestionario = () => httpService.get('/questionario');

export const getCliente = () => httpService.get('/cliente');

export const getCidades = uf => httpService.get(`/opcao/estado/${uf}`);

export const getCidade = id => httpService.get(`/opcao/cidades/${id}`);

export const getProfissoes = () => httpService.get('/profissao');

export const sendCliente = cliente => httpService.post('/cliente', cliente);

export const notifyDocument = request =>
  httpService.post('/cliente/ocorrencia', request);

export const getCorretores = () => httpService.get('/opcao/gerente');

export const buscarEnderecoPorCEP = cep => httpService.get(`/busca/cep/${cep}`);

export const buscarCidade = (estado, busca) =>
  httpService.get(`/opcao/estado/${estado}?cidade=${busca}`);

export const getNacionalidades = () => httpService.get('/opcao/nacionalidade');

export const uploadDocumento = formData =>
  httpService.post('/prospecto/upload', formData);
