import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateSocio, setPanel } from '../Cliente/ClienteActions';
import ClienteFields from '../Cliente/ClienteFields';
import PanelFooter from './PanelFooter';
import PanelToggler from './PanelToggler';

const mapStateToProps = state => ({
  panel: state.panel,
  socios: state.cliente.socios,
  opcoes: state.opcoes,
  haveErrors: state.haveErrors,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSocio,
      setPanel,
    },
    dispatch
  );

const FormDadosPessoais = ({
  socios,
  panel,
  haveErrors,
  updateSocio,
  onContinue,
  setPanel,
}) => {
  return (
    <div className="Panel">
      <header className="mb-2">
        <h3 className="mb-0">Sócios / Cônjuge</h3>
        <p>
          Confira os dados do(s) seu(s) sócio(s) e/ou cônjuge e clique no botão{' '}
          <strong>Continuar</strong>
        </p>
      </header>
      {socios && socios.length > 0 ? (
        socios.map((item, index) => (
          <PanelToggler
            key={index}
            title={item.nome}
            isActive={panel.active === index}
            onOpen={() => setPanel(index)}
            onClose={() => setPanel(null)}
            haveError={item.errors}
          >
            <h4>{item.Nome}</h4>
            <ClienteFields
              onChange={(key, value) => updateSocio(key, value, index)}
              values={item}
            />
          </PanelToggler>
        ))
      ) : (
        <div className="alert PanelSocio__vazio">Nenhum Sócio Cadastrado</div>
      )}
      <PanelFooter>
        {haveErrors && haveErrors.socios ? (
          <div className="alert alert-danger mb-0 py-1 px-2">
            Verifique os dados de todos os seus sócios e/ou cônjuge antes de
            continuar
          </div>
        ) : (
          <div className="m-auto" style={{ maxWidth: 400 }}>
            <button
              onClick={onContinue}
              className="btn btn-block btn-success mb-0"
            >
              Continuar
            </button>
            <small className="text-secondary">
              Clique em <strong>Continuar</strong> se os dados estiverem
              corretos
            </small>
          </div>
        )}
      </PanelFooter>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(FormDadosPessoais);
