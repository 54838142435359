import React from 'react';

const PanelFooter = ({ children }) => {
  return (
    <footer className="fixed-bottom w-100 bg-white py-1">
      <div className="container">
        <div className="col col-lg-8 offset-lg-2">
          <div className="row align-items-center text-center py-1">
            <div className="col">{children}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default PanelFooter;
