import React from 'react';
import { connect } from 'react-redux';
// import Ingresso from '../Ingresso';
import {
  TEXTO_SUCESSO,
  LINK_EMPREENDIMENTO,
  CTA_SITE_EMPREENDIMENTO,
} from '../../constants';

const mapStateToProps = state => ({
  cliente: state.cliente,
  corretores: state.opcoes.corretores.byId,
});

let PanelSuccess = ({ cliente, corretores }) => {
  if (cliente && cliente.data && cliente.data.conferido) {
    return (
      <div className="mt-2 text-center">
        <h2 className="display-4 text-primary">Agora sim!</h2>
        <p
          className="lead"
          dangerouslySetInnerHTML={{ __html: TEXTO_SUCESSO }}
        />
        {/* <Ingresso
          corretor={corretores[cliente.data.codigo_corretor]}
          cliente={cliente.data}
          labelBtnBaixar={CTA_SUCESSO}
          // variant="chequebonus"
        /> */}
        <p className="mt-5 px-3 text-center">
          Caso tenha alguma dúvida, fique à vontade para falar com o seu
          corretor <br className="d-done d-md-inline" /> ou ligar para{' '}
          <br className="d-inline d-md-none" /> <strong>0800 878 7777</strong>
          <br />
          <br />
          <a
            href={LINK_EMPREENDIMENTO}
            style={{ maxWidth: '300px' }}
            className="btn btn-outline-success btn-inline-block"
          >
            {CTA_SITE_EMPREENDIMENTO}
          </a>
        </p>
      </div>
    );
  }

  return null;
};
export default connect(mapStateToProps)(PanelSuccess);
