import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.css';
import { Provider } from 'react-redux';

import configureStore from './app/configureStore';
import { httpService } from './service/Service';
import history from './app/history';
import * as Sentry from '@sentry/browser';

import './index.scss';
import App from './app/App';

const store = configureStore();

httpService.setupInterceptors(store, history);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ae304348a0084aab8d2da1d97758dc0a@sentry.io/1770318',
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
