import * as actions from '~/actionTypes';
import * as api from '../../service/Service';
import * as constants from '../../constants';
import LocalStorage from '../../utils/LocalStorage';
import * as utils from '../../utils/Utils';
import { isAuthenticated } from './loginReducer';

export const doLogin = ({ username, password }) => dispatch => {
  dispatch({
    type: actions.FETCH_LOGIN_PENDING,
  });

  api
    .doLogin({ username, password })
    .then(data => {
      LocalStorage.add(constants.USERGS, {
        ...data,
        expire_in: utils.incrementTime(data.expire_in),
      });

      dispatch({
        type: actions.FETCH_LOGIN_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: actions.FETCH_LOGIN_ERROR,
        payload: {
          errorLogin: error.error || error,
        },
      });
    });
};

export const verifyAuth = () => dispatch => {
  if (isAuthenticated()) {
    dispatch({
      type: actions.FETCH_LOGIN_SUCCESS,
    });
  }
};

export const doLogout = () => dispatch => {
  LocalStorage.remove(constants.USERGS);
  dispatch({
    type: actions.LOGOUT,
  });
};

export const toggleRecoveryCredentials = () => ({
  type: actions.TOGGLE_RECOVERY_CREDENTIALS,
});

export const doRecoveryCredentials = user => dispatch => {
  dispatch({
    type: actions.RECOVERY_PENDING,
  });

  api
    .doRecoveryCredentials(user)
    .then(data => {
      dispatch({
        type: actions.RECOVERY_SUCCESS,
      });
    })
    .catch(error =>
      error.json().then(error => {
        dispatch({
          type: actions.RECOVERY_ERROR,
          payload: {
            error: error.Mensagem,
          },
        });
      })
    );
};
