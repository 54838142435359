import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import ErrorBoundary from './ErrorBoundary';
import Login from '../components/Login/Login';
import Cliente from '../components/Cliente/Cliente';
import { verifyAuth } from '../components/Login/LoginActions';
import Scene from '../components/Scene/Scene';

const mapStateToProps = state => ({
  login: state.login,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      verifyAuth,
    },
    dispatch
  );

class App extends Component {
  compoonentDidMount() {
    this.props.verifyAuth();
  }
  render() {
    const { login } = this.props;

    return <Scene>{login.isAuthenticated ? <Cliente /> : <Login />}</Scene>;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
