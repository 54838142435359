import * as api from '~/service/Service';
import * as actions from '~/actionTypes';
import PDFThumb from '~/utils/PDFThumb';
import { CODIGO_EMPREENDIMENTO } from '~/constants';

export const uploadDocumento = (file, documento) => (dispatch, getState) => {
  dispatch({
    type: actions.UPLOADING_DOCUMENTO,
    payload: documento,
  });

  let prospecto = getState().cliente.data;

  let request = new FormData();

  request.append('arquivo', file);
  request.append('codigo_familia', CODIGO_EMPREENDIMENTO);
  request.append('codigo_prospecto', prospecto.prospecto);
  request.append('codigo_conta', prospecto.conta);
  request.append('codigo_grupo_empresa', prospecto.ge);
  request.append('tipo_pessoa', prospecto.tipo_pessoa);
  request.append('codigo', documento.codigo);
  request.append('tipo', documento.tipo);
  request.append('descricao', documento.descricao);
  request.append('ordem', documento.ordem);
  request.append('sequencia', documento.sequencia);

  api
    .uploadDocumento(request)
    .then(async response => {
      console.log('RESPONSE:', response);
      let novoDocumento = response.prospecto_empreendimento.documentos.find(
        item => {
          return (
            item.codigo === documento.codigo && item.tipo === documento.tipo
          );
        }
      );
      dispatch({
        type: actions.UPLOAD_DOCUMENTO_SUCCESS,
        payload: novoDocumento,
      });
      const Thumb = await PDFThumb.create(novoDocumento.url);
      // dispatch(atualizarProspecto(prospecto));

      dispatch(atualizaDocumento({ ...novoDocumento, Thumb }));
    })
    .catch(error => {
      console.error(error);
      dispatch({
        type: actions.UPLOAD_DOCUMENTO_ERROR,
        payload: { ...documento, error: error.message }, // oh god, is this shit?
      });
    });
};

export const atualizaDocumento = documento => ({
  type: actions.ATUALIZA_DOCUMENTO,
  payload: documento,
});
