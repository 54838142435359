import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getOpcoes,
  getCliente,
  updateCliente,
  sendCliente,
  getQuestionario,
  getCorretores,
} from './ClienteActions';
import { goToNextStep } from '../Scene/SceneAction';
import PanelDadosPessoais from '../Panel/PanelDadosPessoais';
import PanelSocios from '../Panel/PanelSocios';
// import PanelPesquisa from '../Panel/PanelPesquisa/PanelPesquisa';
// import PanelDocumentos from '../Panel/PanelDocumentos';
import PanelSuccess from '../Panel/PanelSuccess';
import PanelEnviar from '../Panel/PanelEnviar';
import PanelStart from '../Panel/PanelStart';
import $ from 'jquery';
import CamposDocumentos from '../Panel/DocumentoUpload/CamposDocumentos';
// import PanelPesquisaSatisfacao from '../Panel/PanelPesquisa/PanelPesquisaSatisfacao';

const mapStateToProps = state => ({
  cliente: state.cliente,
  opcoes: state.opcoes,
  haveErrors: state.haveErrors,
  currentStep: state.scene.currentStep,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOpcoes: getOpcoes,
      getCliente: getCliente,
      updateCliente: updateCliente,
      sendCliente: sendCliente,
      goToNextStep: goToNextStep,
      getQuestionario: getQuestionario,
      getCorretores: getCorretores,
    },
    dispatch
  );

class ValidationContent extends Component {
  componentDidMount() {
    this.props.getCliente();
    this.props.getQuestionario();
    this.props.getCorretores();
    // this.props.getNacionalidades()§

    $(window).scroll(function() {
      if ($(window).scrollTop() >= 50) {
        $('.Scene__header').addClass('is-scroll');
      } else {
        $('.Scene__header').removeClass('is-scroll');
      }
    });
  }
  render() {
    const { cliente, currentStep, goToNextStep } = this.props;
    if (
      cliente &&
      cliente.data &&
      cliente.data.conta &&
      !cliente.data.conferido
    ) {
      return (
        <Fragment>
          {currentStep === -1 && <PanelStart onContinue={goToNextStep} />}
          {currentStep === 0 && (
            <PanelDadosPessoais onContinue={goToNextStep} />
          )}
          {currentStep === 1 && <PanelSocios onContinue={goToNextStep} />}
          {/* {currentStep === 2 && <PanelDocumentos onContinue={goToNextStep} />} */}
          {currentStep === 2 && <CamposDocumentos onContinue={goToNextStep} />}
          {/* {currentStep === 3 && <PanelPesquisa onContinue={goToNextStep} />}
          {currentStep === 4 && (
            <PanelPesquisaSatisfacao onContinue={goToNextStep} />
          )} */}
          {currentStep === 3 && <PanelEnviar />}
        </Fragment>
      );
    }

    return null;
  }
}
ValidationContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ValidationContent);

export default () => (
  <div className="container">
    <div className="row pt-5">
      <div className="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">
        <PanelSuccess />
        <ValidationContent />
      </div>
    </div>
  </div>
);
