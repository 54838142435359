import { Component } from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import get from 'lodash/get';

export const getErrors = ({ values, validations: propsValidation }) => {
  if (!propsValidation) {
    return null;
  }
  const errors = Object.keys(propsValidation).reduce((result, path) => {
    const validations = propsValidation[path];
    if (!isArray(validations)) {
      throw new Error(`validations[${path}] should be an array`);
    }
    return {
      ...result,
      [path]:
        validations
          .map((validation, index) => {
            if (typeof validation !== 'function') {
              throw new Error(
                `validations[${path}][${index}] should be a function`
              );
            }
            const error = validation(get(values, path, ''), values, path);
            if (error && typeof error !== 'string') {
              throw new Error(
                `validations[${path}][${index}] should return a string`
              );
            }
            return error;
          })
          .find(error => error) || null,
    };
  }, {});
  return Object.keys(errors).find(path => errors[path]) ? errors : null;
};

export default class Validator extends Component {
  static propTypes = {
    validations: PropTypes.object,
    values: PropTypes.object,
    children: PropTypes.func.isRequired,
  };

  getErrors = () => getErrors(this.props);

  render() {
    return this.props.children({ errors: this.getErrors() });
  }
}

export const isRequired = value => !value && 'Campo obrigatório';

export const isRequiredOneOf = (values, fields) =>
  fields.reduce((prev, curr) => {
    return prev || values[curr];
  }, false);
