import * as action from '../../actionTypes'

export const setStep = (step) => ({
    type: action.SET_STEP,
    payload: {
        step: step
    }
})

export const goToNextStep = () => (dispatch, getState) => {
    window.scrollTo(0,0)
    dispatch({
        type: action.SET_STEP,
        payload:{
            step: getState().scene.currentStep + 1
        }
    })
}