import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ClienteFields from '../Cliente/ClienteFields';
import { setPanel, updateCliente } from '../Cliente/ClienteActions';
import { goToNextStep } from '../Scene/SceneAction';
import PanelFooter from './PanelFooter';

const mapStateToProps = state => ({
  cliente: state.cliente,
  opcoes: state.opcoes,
  panel: state.panel,
  haveErrors: state.haveErrors,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPanel,
      updateCliente,
      goToNextStep,
    },
    dispatch
  );

const PanelDadosPessoais = ({
  cliente,
  haveErrors,
  updateCliente,
  onContinue,
}) => {
  const { data } = cliente;

  return (
    <div className="Panel">
      <header className="mb-2">
        <h3 className="mb-0">Dados Pessoais</h3>
        <p>
          Confira e corrija, se necessário, os seus dados e clique no botão{' '}
          <strong>Continuar</strong>
        </p>
      </header>
      <ClienteFields
        onChange={(key, value) => updateCliente(key, value)}
        values={data}
      />
      <PanelFooter>
        {haveErrors && haveErrors.cliente ? (
          <div className="alert alert-danger mb-0 py-1 px-2">
            Corrija os seus dados antes de continuar
          </div>
        ) : (
          <>
            <div className="m-auto" style={{ maxWidth: 400 }}>
              <button
                onClick={onContinue}
                className="btn btn-block btn-success mb-0"
              >
                Continuar
              </button>
            </div>
            <small className="text-secondary">
              Clique em <strong>Continuar</strong> se os seus dados estiverem
              corretos
            </small>
          </>
        )}
      </PanelFooter>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(PanelDadosPessoais);
