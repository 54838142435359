export const FETCH_LOGIN_PENDING = 'FETCH_LOGIN_PENDING';
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR';
export const TOGGLE_RECOVERY_CREDENTIALS = 'TOGGLE_RECOVERY_CREDENTIALS';
export const SET_CREDENTIALS_LOGIN = 'SET_CREDENTIALS_LOGIN';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const RECOVERY_PENDING = 'RECOVERY_PENDING';
export const RECOVERY_ERROR = 'RECOVERY_ERROR';
export const RECOVERY_SUCCESS = 'RECOVERY_SUCCESS';
export const FETCH_OPCOES_PENDING = 'FETCH_OPCOES_PENDING';
export const FETCH_OPCOES_SUCCESS = 'FETCH_OPCOES_SUCCESS';
export const FETCH_OPCOES_ERROR = 'FETCH_OPCOES_ERROR';
export const FETCH_CLIENTE_PENDING = 'FETCH_CLIENTE_PENDING';
export const FETCH_CLIENTE_SUCCESS = 'FETCH_CLIENTE_SUCCESS';
export const FETCH_CLIENTE_ERROR = 'FETCH_CLIENTE_ERROR';
export const FETCH_CIDADES_PENDING = 'FETCH_CIDADES_PENDING';
export const FETCH_CIDADES_SUCCESS = 'FETCH_CIDADES_SUCCESS';
export const FETCH_CIDADES_ERROR = 'FETCH_CIDADES_ERROR';
export const FETCH_PROFISSOES_PENDING = 'FETCH_PROFISSOES_PENDING';
export const FETCH_PROFISSOES_SUCCESS = 'FETCH_PROFISSOES_SUCCESS';
export const FETCH_PROFISSOES_ERROR = 'FETCH_PROFISSOES_ERROR';
export const UPDATE_CLIENTE = 'UPDATE_CLIENTE';
export const UPDATE_PESQUISA = 'UPDATE_PESQUISA';
export const UPDATE_SOCIOS = 'UPDATE_SOCIOS';
export const SHOW_PANEL = 'SHOW_PANEL';
export const SEND_CLIENTE_ERROR = 'SEND_CLIENTE_ERROR';
export const INVALID_CLIENTE_ERROR = 'INVALID_CLIENTE_ERROR';
export const SEND_CLIENTE_SUCCESS = 'SEND_CLIENTE_SUCCESS';
export const SEND_CLIENTE_PENDING = 'SEND_CLIENTE_PENDING';
export const LOGOUT = 'LOGOUT';
export const VALIDATE_DOCUMENTS = 'VALIDATE_DOCUMENTS';
export const NOTIFY_DOCUMENTS_PENDING = 'NOTIFY_DOCUMENTS_PENDING';
export const NOTIFY_DOCUMENTS_SUCCESS = 'NOTIFY_DOCUMENTS_SUCCESS';
export const NOTIFY_DOCUMENTS_ERROR = 'NOTIFY_DOCUMENTS_ERROR';
export const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS';
export const SET_STEP = 'SET_STEP';

//Corretores
export const CARREGANDO_CORRETORES = 'CARREGANDO_CORRETORES';
export const CORRETORES_CARREGADOS = 'CORRETORES_CARREGADOS';
export const CORRETORES_CARREGADOS_COM_ERRO = 'CORRETORES_CARREGADOS_COM_ERRO';

//Questionario
export const CARREGANDO_QUESTIONARIO = 'CARREGANDO_QUESTIONARIO';
export const QUESTIONARIO_CARREGADO = 'QUESTIONARIO_CARREGADO';
export const QUESTIONARIO_CARREGADO_ERRO = 'QUESTIONARIO_CARREGADO_ERRO';

export const CARREGANDO_NACIONALIDADE = 'CARREGANDO_NACIONALIDADE';
export const NACIONALIDADE_CARREGADA = 'NACIONALIDADE_CARREGADA';
export const NACIONALIDADE_CARREGADA_COM_ERRO =
  'NACIONALIDADE_CARREGADA_COM_ERRO';

// Documento
export const UPLOADING_DOCUMENTO = 'UPLOADING_DOCUMENTO';
export const UPLOAD_DOCUMENTO_SUCCESS = 'UPLOAD_DOCUMENTO_SUCCESS';
export const UPLOAD_DOCUMENTO_ERROR = 'UPLOAD_DOCUMENTO_ERROR';
export const ATUALIZA_DOCUMENTO = 'ATUALIZA_DOCUMENTO';
