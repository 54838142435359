import login from '../components/Login/loginReducer';
import cliente from '../components/Cliente/clienteReducer';
import opcoes from '../components/Opcoes/opcoesReducer';
import panel from '../components/Panel/panelReducer';
import scene from '../components/Scene/sceneReducer';
import { reducer as formReducer } from 'redux-form';

const getErrorsPesquisa = (values, pesquisa) => {
  let errors = {};
  if (pesquisa) {
    pesquisa.forEach(item => {
      if (item.perguntas) {
        item.perguntas.forEach(pItem => {
          if (pItem.obrigatorio && !values[pItem.Id]) {
            if (
              !pItem.parent ||
              (pItem.parent &&
                values[pItem.parent.pergunta] &&
                parseInt(values[pItem.parent.pergunta]) ===
                  parseInt(pItem.parent.resposta))
            ) {
              errors = {
                ...errors,
                [pItem.Id]: true,
              };
            }
          }
        });
      }
    });
  }

  if (Object.keys(errors).length <= 0) {
    return null;
  }

  return errors;
};

export default (state = {}, action) => {
  state = {
    ...state,
    login: login(state.login, action),
    cliente: cliente(state.cliente, action),
    opcoes: opcoes(state.opcoes, action),
    panel: panel(state.panel, action),
    form: formReducer(state.form, action),
    scene: scene(state.scene, action),
  };

  let errors;
  if (state.cliente && state.opcoes) {
    errors = {
      ...errors,
      pesquisa: getErrorsPesquisa(
        state.cliente.pesquisa,
        state.opcoes.Pesquisa
      ),
    };
  }

  if (state.cliente && state.cliente.data) {
    errors = {
      ...errors,
      cliente: state.cliente.data.errors,
    };
  }

  if (state.cliente && state.cliente.socios) {
    errors = {
      ...errors,
      socios: state.cliente.socios.find(i => i.errors),
    };
  }

  errors = Object.keys(errors).reduce(
    (prev, curr) => prev || errors[curr],
    false
  )
    ? errors
    : null;

  return {
    ...state,
    haveErrors: errors,
  };
};
