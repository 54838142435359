import * as actions from '../../actionTypes';
import * as utils from '../../utils/Utils';
import * as constants from '../../constants';
import { combineReducers } from 'redux';
import { getErrors } from '../Form/Validator';

export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      return false;
    case actions.FETCH_CLIENTE_PENDING:
      return true;
    default:
      return state;
  }
};

const normalizePesquisa = pesquisa => {
  let newPesquisa = {};
  pesquisa.forEach(item => {
    newPesquisa[item.codigo_pergunta] = item.dissertativa
      ? item.dissertativa
      : item.codigo_resposta;
  });

  return newPesquisa;
};

const pesquisa = (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      return normalizePesquisa(action.payload.pesquisa);
    case actions.UPDATE_PESQUISA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const normalizeSocios = socios =>
  socios.map(socio => ({
    ...socio,
    nome: utils.capitalize(socio.nome),
    nascimento: utils.formatDate(socio.nascimento),
  }));

const getErrorsCliente = cliente => {
  let validationType = constants.VALIDATIONS[cliente.tipo]
    ? constants.VALIDATIONS[cliente.tipo]
    : constants.VALIDATIONS.CONJUGE;
  console.log('cliente.tipo: ', cliente.tipo);
  console.log('cliente: ', cliente);
  console.log('validationType: ', validationType);
  return getErrors({ values: cliente, validations: validationType });
};

const socios = (state = [], action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      return normalizeSocios(action.payload.socios).map(item => {
        return {
          ...item,
          errors: getErrorsCliente(item),
        };
      });
    case actions.UPDATE_SOCIOS:
      return state.map((item, index) => {
        if (index !== action.payload.index) {
          return item;
        }

        state = {
          ...item,
          [action.payload.key]: action.payload.value,
        };

        return {
          ...state,
          errors: getErrorsCliente(state),
        };
      });
    default:
      return state;
  }
};

// const documentos = (state = [], action) => {
//   switch (action.type) {
//     case actions.FETCH_CLIENTE_SUCCESS:
//       return action.payload.documentos.map((documento, index) => ({
//         ...documento,
//         uuid: new Date().getTime() + index,
//       }));
//     case actions.UPDATE_DOCUMENTS:
//       return state.map((item, index) => {
//         if (index !== action.payload.index) {
//           return item;
//         }

//         return {
//           ...item,
//           documento_mascara: action.payload.image,
//         };
//       });
//     case UPDATE_DOCUMENT:
//       return state.map(documento => {
//         if (documento.uuid !== action.payload.uuid) return documento;

//         return {
//           ...documento,
//           ...action.payload,
//         };
//       });
//     case actions.NOTIFY_DOCUMENTS_PENDING:
//       return state.map(documento => {
//         if (documento.uuid !== action.payload.uuid) return documento;

//         return {
//           ...documento,
//           IsNotifying: true,
//           IsNotifyError: false,
//         };
//       });
//     case actions.NOTIFY_DOCUMENTS_SUCCESS:
//       return state.map(documento => {
//         if (documento.uuid !== action.payload.uuid) return documento;

//         return {
//           ...documento,
//           IsNotifying: false,
//           IsNotifySuccess: true,
//         };
//       });
//     case actions.NOTIFY_DOCUMENTS_ERROR:
//       return state.map(documento => {
//         if (documento.uuid !== action.payload.documento.uuid) return documento;

//         return {
//           ...documento,
//           IsNotifying: false,
//           IsNotifyError: true,
//         };
//       });
//     default:
//       return state;
//   }
// };

const normalizeCliente = cliente => ({
  ...cliente,
  nome: utils.capitalize(cliente.nome),
  nascimento: utils.formatDate(cliente.nascimento),
  ConferidoDia:
    typeof cliente.conferido === 'string'
      ? utils.formatDate(cliente.conferido, 'DD')
      : '00',
  ConferidoMes:
    typeof cliente.conferido === 'string'
      ? utils.formatDate(cliente.conferido, 'MMMM')
      : '00',
  ConferidoAno:
    typeof cliente.conferido === 'string'
      ? utils.formatDate(cliente.conferido, 'YYYY')
      : '0000',
});

const data = (state = {}, action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      state = {
        ...action.payload,
      };

      delete state.pesquisa;
      delete state.socios;

      state = normalizeCliente(state);
      return {
        ...state,
        errors: getErrorsCliente(state),
      };
    case actions.SEND_CLIENTE_SUCCESS:
      return {
        ...state,
        conferido_dia: utils.formatDate(utils.now(), 'DD'),
        conferido_mes: utils.formatDate(utils.now(), 'MMMM'),
        conferido_ano: utils.formatDate(utils.now(), 'YYYY'),
        conferido: utils.now(),
      };
    case actions.UPDATE_CLIENTE:
      state = {
        ...state,
        [action.payload.key]: action.payload.value,
      };

      return {
        ...state,
        errors: getErrorsCliente(state),
      };
    case actions.LOGOUT:
      return {};
    default:
      return state;
  }
};

const isSendingError = (state = false, action) => {
  switch (action.type) {
    case actions.SEND_CLIENTE_ERROR:
      return action.payload;
    case actions.SHOW_PANEL:
      return false;
    default:
      return state;
  }
};

const isValidateError = (state = false, action) => {
  switch (action.type) {
    case actions.INVALID_CLIENTE_ERROR:
      return true;
    case actions.SHOW_PANEL:
      return false;
    default:
      return state;
  }
};

const isSending = (state = false, action) => {
  switch (action.type) {
    case actions.SEND_CLIENTE_PENDING:
      return true;
    case actions.SEND_CLIENTE_SUCCESS:
    case actions.SEND_CLIENTE_ERROR:
      return false;
    default:
      return state;
  }
};

const isDocumentosError = (state = true, action) => {
  switch (action.type) {
    case actions.VALIDATE_DOCUMENTS:
      return false;
    default:
      return state;
  }
};

const mesmoDocumento = (docA, docB) => {
  return docA.ordem === docB.ordem && docA.codigo === docB.codigo;
};

const documentos = (state = [], action) => {
  switch (action.type) {
    case actions.FETCH_CLIENTE_SUCCESS:
      return action.payload.documentos.map((documento, index) => ({
        ...documento,
        uuid: new Date().getTime() + index,
      }));
    case actions.ATUALIZA_DOCUMENTO:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          return {
            ...item,
            ...action.payload,
          };
        }
        return item;
      });
    case actions.UPLOADING_DOCUMENTO:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          return {
            ...item,
            isUploading: true,
          };
        }
        return item;
      });
    case actions.UPLOAD_DOCUMENTO_SUCCESS:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          delete item.Thumb;
          return {
            ...item,
            ...action.payload,
            isUploading: false,
          };
        }
        return item;
      });
    case actions.UPLOAD_DOCUMENTO_ERROR:
      return state.map(item => {
        if (mesmoDocumento(item, action.payload)) {
          return {
            ...item,
            ...action.payload,
            isUploading: false,
          };
        }
        return item;
      });
    default:
      return state;
  }
};

export default combineReducers({
  isLoading,
  isSending,
  isSendingError,
  isValidateError,
  isDocumentosError,
  data,
  documentos,
  pesquisa,
  socios,
  // documentosUpload,
});
