import * as actions from '../../actionTypes';
import * as utils from '../../utils/Utils';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import _groupBy from 'lodash/groupBy';
import * as constants from '../../constants';
import { combineReducers } from 'redux';
import _map from 'lodash/map';
import _flatten from 'lodash/flatten';
import _sortBy from 'lodash/sortBy';

const isLoading = (state = false, action) => {
  switch (action.type) {
    case actions.FETCH_OPCOES_PENDING:
    case actions.CARREGANDO_QUESTIONARIO:
      return true;
    case actions.FETCH_OPCOES_ERROR:
    case actions.FETCH_OPCOES_SUCCESS:
    case actions.QUESTIONARIO_CARREGADO_ERRO:
    case actions.QUESTIONARIO_CARREGADO:
      return false;
    default:
      return state;
  }
};

const isLoadingCidades = (state = false, action) => {
  switch (action.type) {
    case actions.FETCH_CIDADES_PENDING:
      return true;
    case actions.FETCH_CIDADES_ERROR:
    case actions.FETCH_CIDADES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isErrorLoadingCidades = (state = false, action) => {
  switch (action.type) {
    case actions.FETCH_CIDADES_SUCCESS:
    case actions.FETCH_CIDADES_PENDING:
      return false;
    case actions.FETCH_CIDADES_ERROR:
      return true;
    default:
      return state;
  }
};

const isLoadingProfissoes = (state = false, action) => {
  switch (action.type) {
    case actions.FETCH_PROFISSOES_PENDING:
      return true;
    case actions.FETCH_PROFISSOES_ERROR:
    case actions.FETCH_PROFISSOES_SUCCESS:
      return false;
    default:
      return state;
  }
};

const isErrorLoadingProfissoes = (state = false, action) => {
  switch (action.type) {
    case actions.FETCH_PROFISSOES_SUCCESS:
    case actions.FETCH_PROFISSOES_PENDING:
      return false;
    case actions.FETCH_PROFISSOES_ERROR:
      return true;
    default:
      return state;
  }
};

const normalizeCidades = cidades =>
  cidades.map(item => ({
    ...item,
    descricao: utils.capitalize(item.descricao),
  }));

const buildGruposPesquisa = (pesquisa = []) => {
  let grupos = [];

  pesquisa.forEach(item => {
    if (constants.PERGUNTAS_PARENTES[item.codigo]) {
      item = {
        ...item,
        parent: constants.PERGUNTAS_PARENTES[item.codigo].parent,
      };
    }

    let grupo = {
      codigo_grupo: null,
      descricao_grupo: null,
      perguntas: [],
      grupo_pergunta: item.grupo_pergunta,
    };
    if (!item.codigo_grupo) {
      delete item.codigo_grupo;
      grupo.perguntas.push(item);
      grupos.push(grupo);
    } else {
      let existingGroup = _find(grupos, { codigo_grupo: item.codigo_grupo });
      if (existingGroup) {
        grupo = existingGroup;
        grupo.perguntas.push(item);
      } else {
        grupo = {
          ...grupo,
          ...item,
        };

        grupo.perguntas.push(item);
        grupos.push(grupo);
      }
    }
  });

  return grupos;
};

const cidades = (state = { list: [], byId: {} }, action) => {
  switch (action.type) {
    case actions.FETCH_CIDADES_SUCCESS:
      let normalizedCidades = normalizeCidades(action.payload);
      return {
        list: normalizedCidades,
        byId: _keyBy(normalizedCidades, 'codigo'),
      };
    default:
      return state;
  }
};

const corretores = (state = { list: [], byId: {} }, action) => {
  switch (action.type) {
    case actions.CORRETORES_CARREGADOS:
      let list = _sortBy(
        _flatten(_map(action.payload, 'corretores')),
        'Nome'
      ).filter(item => item.codigo !== constants.CODIGO_CORRETOR_SETPAR);
      return {
        list,
        byId: _keyBy(list, 'codigo'),
      };
    default:
      return state;
  }
};

const profissoes = (state = { list: [], byId: {} }, action) => {
  switch (action.type) {
    case actions.FETCH_PROFISSOES_SUCCESS:
      return {
        list: action.payload,
        byId: _keyBy(action.payload, 'codigo'),
      };
    default:
      return state;
  }
};

const pesquisa = (
  state = { list: [], byId: [], byGrupoPergunta: { PESQUISA: [] } },
  action
) => {
  switch (action.type) {
    case actions.QUESTIONARIO_CARREGADO:
      let list = buildGruposPesquisa(action.payload);
      return {
        list,
        byId: _keyBy(action.payload, 'codigo'),
        byGrupoPergunta: _groupBy(list, 'grupo_pergunta'),
      };
    default:
      return state;
  }
};

const isLoadingNacionalidades = (state = false, action) => {
  switch (action.type) {
    case actions.CARREGANDO_NACIONALIDADE:
      return true;
    case actions.NACIONALIDADE_CARREGADA:
    case actions.NACIONALIDADE_CARREGADA_COM_ERRO:
      return false;
    default:
      return state;
  }
};

const nacionalidades = (state = [], action) => {
  switch (action.type) {
    case actions.NACIONALIDADE_CARREGADA:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  cidades,
  pesquisa,
  profissoes,
  corretores,
  isLoading,
  isLoadingCidades,
  isErrorLoadingCidades,
  isLoadingProfissoes,
  isErrorLoadingProfissoes,
  isLoadingNacionalidades,
  nacionalidades,
});
