import * as api from '../../service/Service';
import * as actions from '../../actionTypes';
import * as utils from '../../utils/Utils';
import * as constants from '../../constants';
import { UPDATE_DOCUMENT } from './clienteReducer';

export const getOpcoes = () => dispatch => {
  dispatch({
    type: actions.FETCH_OPCOES_PENDING,
  });

  api
    .getOpcoes()
    .then(data => {
      dispatch({
        type: actions.FETCH_OPCOES_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: actions.FETCH_OPCOES_ERROR,
        payload: error.mensagem || error.message,
      });
    });
};

export const getQuestionario = () => dispatch => {
  dispatch({
    type: actions.CARREGANDO_QUESTIONARIO,
  });

  api
    .getQuestionario()
    .then(data => {
      dispatch({
        type: actions.QUESTIONARIO_CARREGADO,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: actions.QUESTIONARIO_CARREGADO_ERRO,
        payload: error.mensagem || error.message,
      });
    });
};

export const getCliente = () => dispatch => {
  dispatch({
    type: actions.FETCH_CLIENTE_PENDING,
  });

  api
    .getCliente()
    .then(data => {
      dispatch({
        type: actions.FETCH_CLIENTE_SUCCESS,
        payload: data,
      });

      dispatch(getCidades(data.estado));
      dispatch(getProfissoes());
    })
    .catch(error => {
      if (error.status && error.status === 401) {
        dispatch({
          type: actions.LOGOUT,
        });
      }
      dispatch({
        type: actions.FETCH_CLIENTE_ERROR,
        payload: error.mensagem || error.message,
      });
    });
};

export const getCidades = uf => dispatch => {
  dispatch({
    type: actions.FETCH_CIDADES_PENDING,
  });

  api
    .getCidades(uf)
    .then(data => {
      dispatch({
        type: actions.FETCH_CIDADES_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: actions.FETCH_CIDADES_ERROR,
        payload: error.mensagem || error.message,
      });
    });
};

export const getUpdateCidades = uf => dispatch => {
  dispatch(getCidades(uf));

  dispatch({
    type: actions.UPDATE_CLIENTE,
    payload: {
      key: 'codigo_cidade',
      value: null,
    },
  });
};

export const getProfissoes = uf => dispatch => {
  dispatch({
    type: actions.FETCH_PROFISSOES_PENDING,
  });

  api
    .getProfissoes(uf)
    .then(data => {
      dispatch({
        type: actions.FETCH_PROFISSOES_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: actions.FETCH_PROFISSOES_ERROR,
        payload: error.mensagem || error.message,
      });
    });
};

export const updateCliente = (key, value) => ({
  type: actions.UPDATE_CLIENTE,
  payload: {
    key,
    value,
  },
});

export const updateSocio = (key, value, index) => ({
  type: actions.UPDATE_SOCIOS,
  payload: {
    index,
    key,
    value,
  },
});

export const updatePesquisa = pesquisa => ({
  type: actions.UPDATE_PESQUISA,
  payload: pesquisa,
});

export const sendCliente = () => (dispatch, getState) => {
  let state = getState();
  let haveError = false;

  for (const key in state.cliente.have_error) {
    if (key) {
      haveError = true;
      break;
    }
  }

  if (haveError) {
    dispatch({
      type: actions.INVALID_CLIENTE_ERROR,
    });
    return;
  }

  dispatch({
    type: actions.SEND_CLIENTE_PENDING,
  });

  let pesquisa = Object.keys(state.cliente.pesquisa).map(codigo_pergunta => {
    let resposta = state.cliente.pesquisa[codigo_pergunta];
    let pergunta = state.opcoes.pesquisa.byId[codigo_pergunta];
    let dissertativa = null;
    let codigo_resposta = null;

    if (isNaN(resposta) || pergunta.tipo === constants.TIPOS_PESQUISA.PESO) {
      dissertativa = resposta;

      let comentavel = pergunta.respostas.find(i => i.comentavel);
      codigo_resposta =
        comentavel && comentavel.codigo ? comentavel.codigo : codigo_resposta;
    } else {
      codigo_resposta = parseInt(resposta);
    }

    return {
      codigo_pergunta,
      codigo_resposta,
      dissertativa,
    };
  });

  let cliente = state.cliente.data;

  cliente = {
    ...cliente,
    tel_celular: utils.clearNumber(cliente.tel_celular),
    tel_residencial: utils.clearNumber(cliente.tel_residencial),
    tel_comercial: utils.clearNumber(cliente.tel_comercial),
    rg: utils.clearNumber(cliente.rg),
    cpf: utils.clearNumber(cliente.cpf),
    cep: utils.clearNumber(cliente.cep),
    pesquisa: pesquisa,
    socios: state.cliente.socios,
  };

  api
    .sendCliente(cliente)
    .then(data => {
      dispatch({
        type: actions.SEND_CLIENTE_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      if (error.status && error.status === 401) {
        dispatch({
          type: actions.LOGOUT,
        });
      }

      dispatch({
        type: actions.SEND_CLIENTE_ERROR,
        payload: error && error.message ? error.message : null,
      });
    });
};

export const setPanel = panel => ({
  type: actions.SHOW_PANEL,
  payload: panel,
});

export const validateDocuments = () => ({
  type: actions.VALIDATE_DOCUMENTS,
});

export const notifyDocument = documento => (dispatch, getState) => {
  dispatch({
    type: actions.NOTIFY_DOCUMENTS_PENDING,
    payload: documento,
  });

  let state = getState();

  let request = {
    titulo: 'Conferencia pelo cliente',
    codigo: state.cliente.data.Conta,
    ge: state.cliente.data.ge,
    tipo: documento.tipo,
    documento: documento.url,
  };

  api
    .notifyDocument(request)
    .then(data => {
      dispatch({
        type: actions.NOTIFY_DOCUMENTS_SUCCESS,
        payload: documento,
      });
    })
    .catch(error => {
      if (error.status && error.status === 401) {
        dispatch({
          type: actions.LOGOUT,
        });
      }
      dispatch({
        type: actions.NOTIFY_DOCUMENTS_ERROR,
        payload: {
          documento,
          error: error && error.mensagem ? error.message : 'Error',
        },
      });
    });
};

export const getCorretores = () => dispatch => {
  dispatch({
    type: actions.CARREGANDO_CORRETORES,
  });
  api
    .getCorretores()
    .then(data => {
      dispatch({
        type: actions.CORRETORES_CARREGADOS,
        payload: data,
      });
    })
    .catch(error => {
      dispatch({
        type: actions.CORRETORES_CARREGADOS_COM_ERRO,
        payload: error,
      });
    });
};

export const updateDocuments = (dataUri, index) => ({
  type: actions.UPDATE_DOCUMENTS,
  payload: {
    index,
    image: dataUri,
  },
});

export const updateDocument = documento => ({
  type: UPDATE_DOCUMENT,
  payload: documento,
});
